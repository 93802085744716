window.addEventListener(
    "alert",
    ({ detail: { type, message, redirect = null } }) => {
        Swal.hideLoading();
        Swal.fire({
            title: message,
            icon: type,
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: window.translations.sweetalert_action_btn_ok,
        }).then((result) => {
            if (redirect != null) {
                Swal.fire({
                    title: window.translations.sweetalert_redirect_title,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    timer: 900000,
                    onOpen: () => {
                        swal.showLoading();
                    },
                });
                window.location.replace(redirect);
            }
        });
    },
);

window.addEventListener("swLoading", ({ detail: { message } }) => {
    Swal.fire({
        title:
            message != null && message != undefined
                ? message
                : window.translations.sweetalert_loading_title,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            Swal.showLoading();
        },
    });
});

window.addEventListener(
    "confirmAlert",
    ({ detail: { title, message, event_name } }) => {
        Swal.hideLoading();
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title:
                    title == null
                        ? window.translations.sweetalert_confirm_title
                        : title,
                text:
                    message == null
                        ? window.translations.sweetalert_confirm_message
                        : message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText:
                    window.translations.sweetalert_action_btn_save,
                cancelButtonText:
                    window.translations.sweetalert_action_btn_cancle,
                reverseButtons: true,
                padding: "2em",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
            })
            .then(function (result) {
                if (result.value) {
                    if (event_name != null) {
                        Livewire.emit(event_name);
                        showLoading();
                        timerInterval = setTimeout(function () {
                            swalWithBootstrapButtons.fire(
                                window.translations.sweetalert_all_ok_title,
                                window.translations
                                    .sweetalert_all_ok_description,
                                "success",
                            );
                        }, 2000);
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    swalWithBootstrapButtons.fire(
                        window.translations.sweetalert_canceled_title,
                        window.translations.sweetalert_canceled_desription,
                        "error",
                    );
                }
            });
    },
);

window.addEventListener(
    "openConfirmModal",
    ({
        detail: { event_name, title, message, okText, cancelText, params },
    }) => {
        if (event_name != null) {
            window.dispatchEvent(new Event("open-confirm-modal"));
            if (title != null) {
                document.getElementById("open-confirm-modal-title").innerHTML =
                    title;
            }
            if (message != null) {
                document.getElementById(
                    "open-confirm-modal-description",
                ).innerHTML = message;
            }
            const button = document.getElementById(
                "open-confirm-modal-confirm",
            );
            if (okText != null) {
                button.innerHTML = okText;
            }

            const buttonCancel = document.getElementById(
                "open-confirm-modal-cancel",
            );

            if (cancelText != null) {
                buttonCancel.innerHTML = cancelText;
            }
            button.setAttribute(
                "onclick",
                "Livewire.emit('" + event_name + "', " + params + ")",
            );
            button.onclick = function () {
                Livewire.emit(event_name, params);
                showLoading();
            };
        }
    },
);

window.addEventListener(
    "alertModal",
    ({ detail: { type, title, message = null, redirectUrl = null } }) => {
        Swal.hideLoading();
        Swal.close();
        const alertImage = document.getElementById("alert-modal-image");

        if (alertImage != null) {
        }
        if (type == "error") {
            alertImage.src =
                window.location.protocol +
                "//" +
                window.location.host +
                "/assets/eAgora/img/directions.svg";
        } else {
            alertImage.src =
                window.location.protocol +
                "//" +
                window.location.host +
                "/assets/eAgora/icons/common/rocket.svg";
        }
        const alertModal = document.getElementById("alert-modal");
        document.getElementById("alert-modal-title").innerHTML = title;
        // document.getElementById('alert-modal-message').innerText = message;
        const alertModalButton = document.getElementById("alert-modal-button");
        alertModalButton.onclick = function () {
            $(alertModal).modal("hide");
            if (redirectUrl != null) {
                location.href = redirectUrl;
                showLoading();
            }
        };
        $(alertModal).modal("show");
    },
);
